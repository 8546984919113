import React from "react";

import "./App.css";

import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Layout from "./components/layout";
import Home from "./Pages/home";
import { ParallaxProvider } from "react-scroll-parallax";
import { ChakraProvider } from "@chakra-ui/react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Feature from "./Pages/feature";

export default function App() {
  return (
    <ChakraProvider>
      {/* <ParallaxProvider> */}
      <Router>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="/feature" element={<Feature />} />
          </Route>
        </Routes>
      </Router>
      {/* </ParallaxProvider> */}
    </ChakraProvider>
  );
}
